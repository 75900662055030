
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { defaultConfigData, IDefaultConfigData } from '@/business/DefaultConfigData';
import { IOrderForm } from '@/types/placeOrderTypes';
import ConfigForm from './ConfigForm.vue';
import TitleList from './TitleList.vue';
import {
  getStyleList,
  getStyleInfo,
  copyStyle,
  getFbdStyleList,
  getFbdStyleConfig,
  creatFbdStyle,
  editFbdStyle,
  deleteFbdStyle,
  uploadStyle,
  copyFBDStyle
} from '@/api/Allservices';
import { getOrgList } from '@/api/login';
import { getFileMd5 } from '../helper';
// @ts-ignore
import Oss from 'ali-oss';
import { getOssInfo, getMaterialFile, creatMaterialFile, getOrgConfig, setOrgConfig } from '@/api/placeOrder';
// @ts-ignore
import img from '@/assets/book.jpg';
@Component({
  components: {
    ConfigForm, TitleList
  }
})
export default class FbdStyleLibrary extends Vue {
  @Prop() serverType!: string;
  @Prop() stage?: string;
  @Prop() subject?: string;
  @Prop() form!: IOrderForm;
  visible = false;
  uploadStyleVisible = false;
  titleListVisible = false;
  refreshDialog = 0;
  uploading = false;
  CurrConfig: any = null;
  bookImage = img;
  conditions = {
    limit: 20,
    skip: 0,
    customer_id: 0,
  }
  styleList = [];
  count = 0;
  page = 1;
  types: any = {};
  size: any = {};
  listKeyword = this.$route.query.search || '';
  fileList: { name: string; url: string; path: string; md5: string; size: number; extension: string }[] = [];
  orgList = [];
  orgKeyword = '';
  titleData: any = []

  get org () {
    return this.$store.state.user?.orgs[0]?.orgId || 0;
  }

  get isTemplatePath () {
    return this.$route.name === 'template';
  }

  get selectedType () {
    const { type } = this.$route.query;
    const key = Object.keys(this.types).find(k => this.types[k] === type);
    return key || '全部';
  }

  get selectedSize () {
    const { size } = this.$route.query;
    const key = Object.keys(this.size).find(k => this.size[k] === size);
    return key || '全部';
  }

  get orgSearchList () {
    return this.orgList.filter((item: any) => {
      return item.orgName.indexOf(this.orgKeyword) !== -1;
    });
  }

  async created () {
    await this.getStyleInfo();
    if (this.org === 2) {
      this.getOrgList();
    }
    this.conditions.customer_id = this.org;
  }

  @Watch('conditions', { deep: true })
  async getList () {
    this.uploading = true;
    const { type, size, search } = this.$route.query;
    if (this.serverType === 'STU2TEA' || this.isTemplatePath) {
      const res = await getFbdStyleList({
        search,
        ...this.conditions
      });
      this.styleList = res.data;
      this.count = res.count;
    } else {
      const res = await getStyleList({
        type: type || 'all',
        size: size || 'all',
        search,
        ...this.conditions
      });
      this.styleList = res.data;
      this.count = res.count;
    }
    this.uploading = false;
  }

  handleType (command: string) {
    const { type } = this.$route.query;
    if (type !== command) {
      this.$router.replace({ query: { ...this.$route.query, type: command } });
      this.getList();
    }
  }

  handleSize (command: string) {
    const { size } = this.$route.query;
    if (size !== command) {
      this.$router.replace({ query: { ...this.$route.query, size: command } });
      this.getList();
    }
  }

  async getStyleInfo () {
    const { type, size } = await getStyleInfo({});
    this.types = type;
    this.size = size;
  }

  searchList (val: string) {
    this.$router.replace({ query: { ...this.$route.query, search: val } });
    this.getList();
  }

  async getOrgList () {
    const res = await getOrgList();
    this.orgList = res;
  };

  async creatStyle (id?: number) {
    if (this.serverType === 'WORD2FBD') {
      this.$router.push(`style-demo-v2?customer_id=${this.conditions.customer_id}&id=${this.$route.query.id}${id ? '&style_id=' + id : ''}${this.$route.query.reEdit ? '&reEdit=' + this.$route.query.reEdit : ''}${this.$route.query.search ? '&search=' + this.$route.query.search : ''}`);
    } else if (this.serverType === 'STU2TEA' || this.isTemplatePath) {
      this.visible = true;
      const res = await getFbdStyleConfig({
        style_id: id
      });
      this.CurrConfig = res.config ? res : { config: defaultConfigData };
    }
  }

  async createTitleList (id?: number) {
    if (this.serverType === 'STU2TEA') {
      // const res = await getFbdStyleConfig({
      //   style_id: id
      // });
      // this.titleData = res.config ? res : { config: defaultConfigData };
      // 从接口获取
      this.titleData = this.form.xdoc_planning[0].config.stu2tea_title || [];
    }
    if (this.isTemplatePath || !this.titleData.length) {
      const orgConfig = await getOrgConfig({ customer_id: this.org });
      if (!orgConfig) {
        this.$message.error('该企业配置错误！');
      }
      const fbdConfig = orgConfig.fbd_align_config;
      if (fbdConfig) {
        this.titleData = fbdConfig.titleList || [];
      } else {
        this.titleData = [];
        this.$message.error('请先配置标题清单');
      }
    }
    this.titleListVisible = true;
    this.refreshDialog += 1;
  }
  closeTitleList () {
    const ifSaveChange = (this.$refs.titleList as any).ifSaveChange;
    if (ifSaveChange) {
      this.titleListVisible = false;
    } else {
      this.titleListVisible = true;
      this.$confirm('新内容尚未保存是否确认关闭？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.titleListVisible = false;
      });
    }
  }
  async saveTitleList (newData: any) {
    if (this.isTemplatePath) {
      try {
        await setOrgConfig({
          customer_id: this.org,
          fbd_align_config: {
            titleList: this.titleData
          }
        });
        this.$message.success('保存成功');
      } catch (e) {
        this.$message.error('保存失败');
      }
    } else {
      this.$emit('saveTitleList', newData);
    }
    this.titleListVisible = false;
  }

  async deleteStyle (id?: number) {
    if (this.serverType === 'STU2TEA' || this.isTemplatePath) {
      await deleteFbdStyle({
        style_id: id
      });
      await this.getList();
      this.$message.success('删除成功');
    }
  }

  handleStyle (id: number) {
    this.$emit('save', id);
  }

  async saveConfigHandle (config: IDefaultConfigData, name: string) {
    console.log(config, name);
    if (this.CurrConfig._id) {
      await editFbdStyle({
        name,
        config,
        customer_id: this.conditions.customer_id,
        style_id: this.CurrConfig._id
      });
      this.$message.success('修改成功');
    } else {
      await creatFbdStyle({
        name,
        config,
        customer_id: this.conditions.customer_id,
      });
      this.$message.success('创建成功');
    }
    this.getList();
    this.visible = false;
  }

  async uploadFile (file: any) {
    if (!/(\.pro|\.fbd)$/.test(file.file.name.toLowerCase())) {
      this.$notify({
        title: '警告',
        message: '请上传以 pro、fbd 为后缀格式的文件',
        type: 'warning'
      });
      return;
    }
    const md5: string = (await getFileMd5(file.file)) as string;
    const auth = await getOssInfo({ path_alias: 'file' });
    const { access_key_id, access_key_secret_id, bucket, region, key } = auth;
    const extension = file.file.name.split('.')[file.file.name.split('.').length - 1].toLowerCase();
    try {
      const material = await getMaterialFile({
        md5
      });
      const { path } = material;
      if (path) {
        const index = this.fileList.findIndex(file => file.extension === extension);
        if (index > -1) {
          this.fileList.splice(index, 1, {
            name: file.file.name,
            url: 'https://hexin-worksheet.oss-cn-shanghai.aliyuncs.com/' + path,
            path,
            md5,
            extension,
            size: file.file.size
          });
        } else {
          this.fileList.push({
            name: file.file.name,
            url: 'https://hexin-worksheet.oss-cn-shanghai.aliyuncs.com/' + path,
            path,
            md5,
            extension,
            size: file.file.size
          });
        }
        return;
      }
      const client = new Oss({ region, accessKeyId: access_key_id, accessKeySecret: access_key_secret_id, bucket });
      await client.multipartUpload(`${key}/${md5}.${extension}`, file.file, {
        parallel: 5,
        partSize: 1024 * 1024,
        timeout: 4000000,
        progress: function (p: number) {
          this.uploadingProgress = +(p * 100).toFixed(2);
        }
      });
      await creatMaterialFile({
        md5,
        name: file.file.name,
        extension,
        path: `${key}/${md5}.${extension}`
      });
      const index = this.fileList.findIndex(file => file.extension === extension);
      if (index > -1) {
        this.fileList.splice(index, 1, {
          name: file.file.name,
          url: 'https://hexin-worksheet.oss-cn-shanghai.aliyuncs.com/' + path,
          path,
          md5,
          extension,
          size: file.file.size
        });
      } else {
        this.fileList.push({
          name: file.file.name,
          url: 'https://hexin-worksheet.oss-cn-shanghai.aliyuncs.com/' + path,
          path,
          md5,
          extension,
          size: file.file.size
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  // 上传失败
  upError () {
    this.fileList = [];
    this.$notify.error({
      title: '错误',
      message: '文件上传失败'
    });
  }

  // 移除列表
  upRemve (file: any) {
    const index = this.fileList.findIndex(item => item.md5 === file.md5);
    this.fileList.splice(index, 1);
  }

  async uploadStyleHandle () {
    const fbd = this.fileList.find(item => item.extension === 'fbd');
    const pro = this.fileList.find(item => item.extension === 'pro');
    if (!fbd) return this.$message.error('请上传 fbd 文件');
    if (!pro) return this.$message.error('请上传 pro 文件');
    await uploadStyle({
      customer_id: this.conditions.customer_id,
      fbd_url: fbd?.url,
      pro_url: pro?.url
    });
    this.uploadStyleVisible = false;
    this.$message.success('上传成功');
  }

  async CopyStyle (_id: string) {
    await copyFBDStyle({
      _id
    });
    this.$message.success('复制成功');
    this.getList();
  }

  customerIdChange (id: number) {
    this.conditions.customer_id = id;
    this.conditions.skip = 0;
    this.page = 1;
  }
}
