
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({})
export default class ConfigForm extends Vue {
  @Prop() titleData: any
  newTitle = ''
  ifSaveChange = true

  mounted () {
    console.log('打开titleList');

    // 滚动条滚到底 (展示最新一条数据)
    (this.$refs.theBody as HTMLElement).scrollTop = (this.$refs.theBody as HTMLElement).scrollHeight;
  }

  // 保存按钮
  saveHandle () {
    if (this.newTitle) this.titleData.push(this.newTitle);
    this.newTitle = '';
    this.$emit('save', this.titleData);
    this.ifSaveChange = true;
  }

  // 关闭弹窗
  closeHandle () {
    if (this.ifSaveChange) {
      this.newTitle = '';
      this.$emit('close');
    } else {
      this.$confirm('标题清单尚未保存是否确认关闭？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.ifSaveChange = true;
        this.newTitle = '';
        this.$emit('close');
      });
    }
  }

  // 输入
  editHandle (val: string) {
    this.ifSaveChange = false;
  }

  // 输入
  inputHandle (val: string) {
    this.newTitle = val;
    this.ifSaveChange = false;
  }

  // 回车键
  enterEditHandle (e: any, i: number) {
    // 插入操作
    this.titleData.splice(i + 1, 0, '');
    // 插入新输入框，给新输入框焦点
    ((this.$refs.myInput as Element[])[i + 1] as HTMLElement)?.focus();
  }

  // 回车键
  enterEditChapterHandle () {
    if (this.newTitle) {
      this.titleData.push(this.newTitle);
      this.newTitle = '';
    } else {
      this.$message({ message: '标题不能为空', type: 'warning' });
    }

    // 滚动条滚到底 (展示最新一条数据)
    this.$nextTick(() => {
      (this.$refs.theBody as HTMLElement).scrollTop = (this.$refs.theBody as HTMLElement).scrollHeight;
    });
  }

  // 删除键
  deleteEditChapterHandle (e: any, i: number) {
    // 若输入框为空则删除该条记录
    if (!e) this.titleData.splice(i, 1);
  }

  // 粘贴
  handlePaste (e: any, chapter: any) {
    const text = (e.clipboardData || (window as any).clipboardData).getData('text');
    const arrText = text.split(/[(\r\n)\r\n]+/); // 以转行符切割文本字符串
    if (arrText.length > 1) {
      arrText.forEach((text: any) => {
        this.titleData.push(text);
      });
      setTimeout(() => {
        this.newTitle = '';
      }, 0);
    }
  }

  // 清空
  clearHandle () {
    this.$confirm('确认清空当前标题记录？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.titleData.splice(0, this.titleData.length);
    });
    this.ifSaveChange = false;
  }

  // 复制内容
  copy () {
    const t = this.titleData.join('\n');

    if (!navigator.clipboard) {
      // 如果不支持，则使用传统的 document.execCommand("copy") 方式
      const textArea = document.createElement('textarea');
      textArea.value = t;
      textArea.style.position = 'fixed';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      this.$message.success('复制成功');
      return;
    }
    // 使用 Clipboard API 复制内容到剪切板
    navigator.clipboard.writeText(t).then(
      function () {
        console.log('复制成功');
      },
      function (err) {
        console.log('复制失败', err);
      }
    );
    this.$message.success('复制成功');
  }
}
